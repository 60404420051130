import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"

const AppendixPage = () => (
  <Layout>
    <Seo title="Appendix" />
    <div className="container">
      <h1 className="h2">Appendix to the History of Shotton Steel</h1>
      <p className="lead">APPENDIX to a comprehensive chronological history of Tata Shotton Works, while in the ownership of John Summers and Sons Limited (1896-1967),British Steel Corporation (1967-1988), British Steel PLC (1988-1999), Corus PLC (1999-2007) and Tata Steel Group since 2007.</p>
      <h5>Contents</h5>
      <nav className="col-sm-12" style={{ "margin-bottom": "30px;" }}>
        <ul className="">
          <li><a href="#canalisation">Canalisation of the Dee Estuary and river</a></li>
          <li><a href="#hawarden-bridge">Hawarden Bridge rail bridge</a></li>
          <li><a href="#statistics">Number of employees & other statistics</a></li>
          <li><a href="#railway">Shotton’s internal railway system and loco fleet</a></li>
          <li><a href="#shipping-fleet">Summers&rsquo; shipping fleet and jetties</a></li>
          <li><a href="#foundry-building">The Foundry building</a></li>
          <li><a href="#housing-the-workers">Housing the workers</a></li>
          <li><a href="#land-ownership">Summers/British Steel land ownership</a></li>
          <li><a href="#on-site-brewery">On Site Brewery</a></li>
          <li><a href="#grandfather-clocks">Harry Summers’ grandfather clocks</a></li>
          <li><a href="#rifle-and-pistol-club">Works’ Rifle and Pistol Club</a></li>
          <li><a href="#sssi-sties">SSSI sties and tern breeding rafts</a></li>
          <li><a href="#weather-station">Weather station</a></li>
          <li><a href="#grandfather-clocks">Harry Summers’ grandfather clocks</a></li>
          <li><a href="#sailing-club">Shotwick Lake and sailing club</a></li>
          <li><a href="#artists">The artist, Terence Cuneo  and the sculptor, Charles Fraughan</a></li>
          <li><a href="#deeside-industrial-park">Deeside Industrial Park</a></li>
        </ul>
      </nav>
      
        <div id="canalisation"> 
          <h2 className="h3">Canalisation of the Dee Estuary and river</h2>
          <p>Between 1732 and 1736, Dutch engineers constructed a five-mile long artificial channel on the North East Wales side of the estuary which meant that large parts of the Dee estuary were no longer submerged by daily tides. </p>
          <p>The work was funded by local merchants and Chester Corporation in an attempt to improve navigation for shipping and reduce silting along the river Dee to the port of Chester at a time when the city’s shipping trade was in decline.</p>
          <p>In 1895, Summers bought 40 acres of marshland close to Hawarden Bridge rail bridge from the Manchester, Sheffield and Lincoln Railway Company with an option on a further 50 acres. The steel company eventually bought some 10,000 acres of Dee Estuary marshland of which 1,000 acres were used for industrial purposes as they expanded their Shotton business in the 20th century.</p>
          <p>In addition to providing cheap land for Summers, canalisation enabled the company to build jetties along the River Dee so that small coasters could deliver raw materials such as pig iron to the works and move out finished steel products to Liverpool for transfer to ocean-going vessels. The export of galvanised sheets was the mainstay of the business as it developed in the 20th century. </p>
        </div>
        <div id="hawarden-bridge"> 
          <h2 className="h3">Hawarden Bridge rail bridge</h2>
          <p>The 165 metre long rail bridge spans the river Dee at Hawarden Bridge, the name adopted for the new John Summers and Sons works when it first opened in 1896. It was built by the Manchester, Sheffield and Lincoln Railway Company at a cost of £71,000 and was completed in 1889. It carries a double line of rail track with a footway for pedestrians on each side.</p>
          <p>In 1911 John Summers and Sons signed an agreement with the railway allowing directors, officers, servants and work people of Summers and children and others bringing meals to such work people to use the footways. Everyone else had to pay a half penny each way. This existed until 1972 when a new agreement was signed with the new owners, British Rail. </p>
          <p>The outer sections of the bridge are fixed span and the two centre sections swing span. The opening mechanism was housed in a control tower on the works side of the river until its demolition because of deterioration in 1976.</p>
          <p>The mechanism for the swing bridge was initially powered by steam but following its conversion to electrical control the bridge controller operated levers in a room at the top of the control tower. One locked the gates at each end of the pedestrian walkway so that the bridge could swing open for river traffic. Signals on the Queensferry side of the bridge gave boats priority over trains so that they could take full advantage of the tides.</p>
          <p>After the river traffic had petered out, the railway company maintained its claim on operation of the bridge by opening it every Sunday at noon. It was last swung open in 1953 when the Duke of Edinburgh visited the works to open new “heavy end” developments. </p>
          <p>In an article celebrating the centenary of the bridge’s opening, the July 1989 edition of the works’ newspaper, STEEL NEWS reported that without Hawarden rail bridge it was unlikely that a steelworks would have been built on the banks of the Dee. “It opened up a vast area of land suitable for industry and John Summers and Sons saw the potential for a steelworks which they started up seven years later. </p>
          <p>“Generations of steelworkers from the towns of Connah’s Quay and Shotton used the bridge’s footways to get to the works on the other side of the river and special passes were issued at one time. </p>
          <p>“At one time boats had priority over trains so that they could take full advantage of the tides. Today (1989) trains carry passengers between North Wales and Merseyside and bring in thousands of tonnes of hot and cold rolled strip steel needed to keep Shotton’s cold rolling mills and coil coating lines busy.” </p>
          <p>Today (2014) train loads of coils of cold reduced strip from Tata Steel’s Port Talbot Works in South Wales cross the bridge to feed the works’ coil coating lines. </p>
          <p>In November 2014 Network Rail completed an £8 million restoration of the bridge, now a Grade 11 listed structure. Some 130 tonnes of additional steel plate, 12,000 bolts and 7,000 litres of paint were used in the work which will enable two freight trains to cross at the same time at 25 m.p.h.  Previously, because of a weakened structure, only one train could cross the bridge at a time at a reduced speed of 20 m.p.h.  </p>
      </div>
      <div id="statistics">
      <h2 className="h3">Number of employees & other statistics</h2>
      <table className="table table-striped">
      <thead>
      <tr>
        <th scope="col">Year</th>
        <th scope="col">Number of Employees</th>
        <th scope="col">Annual production levels</th>
      </tr>
    </thead>
  <tbody>
  <tr>
  <td style={{ width: "86px" }}>
  <p><strong>1896</strong></p>
  </td>
  <td style={{ width: "130px" }}>
  <p><strong>250</strong></p>
  </td>
  <td style={{ width: "296px" }}>
  <p><strong>30,000 tons</strong></p>
  </td>
  </tr>
  <tr>
  <td style={{ width: "86px" }}>
  <p><strong>1900</strong></p>
  </td>
  <td style={{ width: "130px" }}>
  <p><strong>1,750</strong></p>
  </td>
  <td style={{ width: "296px" }}>
  <p><strong>150,000 tons</strong></p>
  </td>
  </tr>
  <tr>
  <td style={{ width: "86px" }}>
  <p><strong>1904</strong></p>
  </td>
  <td style={{ width: "130px" }}>
  <p>&nbsp;</p>
  </td>
  <td style={{ width: "296px" }}>
  <p><strong>21,000 tons of molten steel</strong></p>
  </td>
  </tr>
  <tr>
  <td style={{ width: "86px" }}>
  <p><strong>1907</strong></p>
  </td>
  <td style={{ width: "130px" }}>
  <p><strong>3,400</strong></p>
  </td>
  <td style={{ width: "296px" }}>
  <p><strong>4,500 tons of sheet per week</strong></p>
  </td>
  </tr>
  <tr>
  <td style={{ width: "86px" }}>
  <p><strong>1911</strong></p>
  </td>
  <td style={{ width: "130px" }}>
  <p><strong>3,400</strong></p>
  </td>
  <td style={{ width: "296px" }}>
  <p><strong>227,000 tons</strong></p>
  </td>
  </tr>
  <tr>
  <td style={{ width: "86px" }}>
  <p><strong>1915</strong></p>
  </td>
  <td style={{ width: "130px" }}>
  <p>&nbsp;</p>
  </td>
  <td style={{ width: "296px" }}>
  <p><strong>233,000 tons of molten steel</strong></p>
  </td>
  </tr>
  <tr>
  <td style={{ width: "86px" }}>
  <p><strong>1928</strong></p>
  </td>
  <td style={{ width: "130px" }}>
  <p><strong>5,000</strong></p>
  </td>
  <td style={{ width: "296px" }}>
  <p><strong>262,000 tons</strong></p>
  </td>
  </tr>
  <tr>
  <td style={{ width: "86px" }}>
  <p><strong>1945</strong></p>
  </td>
  <td style={{ width: "130px" }}>
  <p><strong>5,500</strong></p>
  </td>
  <td style={{ width: "296px" }}>
  <p><strong>300,000 tons</strong></p>
  </td>
  </tr>
  <tr>
  <td style={{ width: "86px" }}>
  <p><strong>1946</strong></p>
  </td>
  <td style={{ width: "130px" }}>
  <p><strong>5,500</strong></p>
  </td>
  <td style={{ width: "296px" }}>
  <p><strong>325,000 tons</strong></p>
  </td>
  </tr>
  <tr>
  <td style={{ width: "86px" }}>
  <p><strong>1947</strong></p>
  </td>
  <td style={{ width: "130px" }}>
  <p><strong>5,850</strong></p>
  </td>
  <td style={{ width: "296px" }}>
  <p><strong>390,000 tons</strong></p>
  </td>
  </tr>
  <tr>
  <td style={{ width: "86px" }}>
  <p><strong>1948</strong></p>
  </td>
  <td style={{ width: "130px" }}>
  <p><strong>5,900</strong></p>
  </td>
  <td style={{ width: "296px" }}>
  <p>&nbsp;</p>
  </td>
  </tr>
  <tr>
  <td style={{ width: "86px" }}>
  <p><strong>1953</strong></p>
  </td>
  <td style={{ width: "130px" }}>
  <p><strong>7,000</strong></p>
  </td>
  <td style={{ width: "296px" }}>
  <p>&nbsp;</p>
  </td>
  </tr>
  <tr>
  <td style={{ width: "86px" }}>
  <p><strong>1955</strong></p>
  </td>
  <td style={{ width: "130px" }}>
  <p><strong>8,000</strong></p>
  </td>
  <td style={{ width: "296px" }}>
  <p>&nbsp;</p>
  </td>
  </tr>
  <tr>
  <td style={{ width: "86px" }}>
  <p><strong>1956</strong></p>
  </td>
  <td style={{ width: "130px" }}>
  <p>&nbsp;</p>
  </td>
  <td style={{ width: "296px" }}>
  <p><strong>1.5 tonnes, molten steel</strong></p>
  </td>
  </tr>
  <tr>
  <td style={{ width: "86px" }}>
  <p><strong>1957</strong></p>
  </td>
  <td style={{ width: "130px" }}>
  <p><strong>10,000</strong></p>
  </td>
  <td style={{ width: "296px" }}>
  <p><strong>1m.tons of molten steel</strong></p>
  </td>
  </tr>
  <tr>
  <td style={{ width: "86px" }}>
  <p><strong>1960s</strong></p>
  </td>
  <td style={{ width: "130px" }}>
  <p><strong>13,200</strong></p>
  </td>
  <td style={{ width: "296px" }}>
  <p>&nbsp;</p>
  </td>
  </tr>
  <tr>
  <td style={{ width: "86px" }}>
  <p><strong>1965</strong></p>
  </td>
  <td style={{ width: "130px" }}>
  <p>&nbsp;</p>
  </td>
  <td style={{ width: "296px" }}>
  <p><strong>1.5 tons of molten steel</strong></p>
  </td>
  </tr>
  <tr>
  <td style={{ width: "86px" }}>
  <p><strong>1967</strong></p>
  </td>
  <td style={{ width: "130px" }}>
  <p><strong>12,000</strong></p>
  </td>
  <td style={{ width: "296px" }}>
  <p>&nbsp;</p>
  </td>
  </tr>
  <tr>
  <td style={{ width: "86px" }}>
  <p><strong>1976</strong></p>
  </td>
  <td style={{ width: "130px" }}>
  <p><strong>10,700</strong></p>
  </td>
  <td style={{ width: "296px" }}>
  <p><strong>27,500 tonnes/ week</strong></p>
  </td>
  </tr>
  <tr>
  <td style={{ width: "86px" }}>
  <p><strong>December</strong></p>
  <p><strong>1979</strong></p>
  </td>
  <td style={{ width: "130px" }}>
  <p><strong>10,483</strong></p>
  </td>
  <td style={{ width: "296px" }}>
  <p>&nbsp;</p>
  </td>
  </tr>
  <tr>
  <td style={{ width: "86px" }}>
  <p><strong>June 1980</strong></p>
  </td>
  <td style={{ width: "130px" }}>
  <p><strong>4,200</strong></p>
  </td>
  <td style={{ width: "296px" }}>
  <p>&nbsp;</p>
  </td>
  </tr>
  <tr>
  <td style={{ width: "86px" }}>
  <p><strong>December</strong></p>
  <p><strong>1980</strong></p>
  </td>
  <td style={{ width: "130px" }}>
  <p><strong>3,595</strong></p>
  </td>
  <td style={{ width: "296px" }}>
  <p>&nbsp;</p>
  </td>
  </tr>
  <tr>
  <td style={{ width: "86px" }}>
  <p><strong>1981</strong></p>
  </td>
  <td style={{ width: "130px" }}>
  <p><strong>2,673</strong></p>
  </td>
  <td style={{ width: "296px" }}>
  <p>&nbsp;</p>
  </td>
  </tr>
  <tr>
  <td style={{ width: "86px" }}>
  <p><strong>1982</strong></p>
  </td>
  <td style={{ width: "130px" }}>
  <p><strong>2,463</strong></p>
  </td>
  <td style={{ width: "296px" }}>
  <p>&nbsp;</p>
  </td>
  </tr>
  <tr>
  <td style={{ width: "86px" }}>
  <p><strong>1986</strong></p>
  </td>
  <td style={{ width: "130px" }}>
  <p><strong>2,234</strong></p>
  </td>
  <td style={{ width: "296px" }}>
  <p>&nbsp;</p>
  </td>
  </tr>
  <tr>
  <td style={{ width: "86px" }}>
  <p><strong>1989</strong></p>
  </td>
  <td style={{ width: "130px" }}>
  <p><strong>2,141</strong></p>
  </td>
  <td style={{ width: "296px" }}>
  <p>&nbsp;</p>
  </td>
  </tr>
  <tr>
  <td style={{ width: "86px" }}>
  <p><strong>1990</strong></p>
  </td>
  <td style={{ width: "130px" }}>
  <p><strong>2,046</strong></p>
  </td>
  <td style={{ width: "296px" }}>
  <p>&nbsp;</p>
  </td>
  </tr>
  <tr>
  <td style={{ width: "86px" }}>
  <p><strong>1991</strong></p>
  </td>
  <td style={{ width: "130px" }}>
  <p><strong>1,823</strong></p>
  </td>
  <td style={{ width: "296px" }}>
  <p>&nbsp;</p>
  </td>
  </tr>
  <tr>
  <td style={{ width: "86px" }}>
  <p><strong>1992</strong></p>
  </td>
  <td style={{ width: "130px" }}>
  <p><strong>1,618</strong></p>
  </td>
  <td style={{ width: "296px" }}>
  <p><strong>845,000 tonnes of coated products</strong></p>
  </td>
  </tr>
  <tr>
  <td style={{ width: "86px" }}>
  <p><strong>1993/94</strong></p>
  </td>
  <td style={{ width: "130px" }}>
  <p>&nbsp;</p>
  </td>
  <td style={{ width: "296px" }}>
  <p><strong>880,000 tonnes coated products</strong></p>
  </td>
  </tr>
  <tr>
  <td style={{ width: "86px" }}>
  <p><strong>1995</strong></p>
  </td>
  <td style={{ width: "130px" }}>
  <p>&nbsp;</p>
  </td>
  <td style={{ width: "296px" }}>
  <p><strong>1.1 million tonnes, cold reduced and coated products and non-prime arisings</strong></p>
  </td>
  </tr>
  <tr>
  <td style={{ width: "86px" }}>
  <p><strong>1997</strong></p>
  </td>
  <td style={{ width: "130px" }}>
  <p><strong>1,600</strong></p>
  </td>
  <td style={{ width: "296px" }}>
  <p><strong>900,000 tonnes of metallic and organic coated strip</strong></p>
  </td>
  </tr>
  <tr>
  <td style={{ width: "86px" }}>
  <p><strong>August 2003</strong></p>
  </td>
  <td style={{ width: "130px" }}>
  <p><strong>650</strong></p>
  </td>
  <td style={{ width: "296px" }}>
  <p>&nbsp;</p>
  </td>
  </tr>
  <tr>
  <td style={{ width: "86px" }}>
  <p><strong>2007</strong></p>
  </td>
  <td style={{ width: "130px" }}>
  <p><strong>550 Corus Colors</strong></p>
  <p><strong>300 Corus Living Solutions</strong></p>
  </td>
  <td style={{ width: "296px" }}>
  <p>&nbsp;</p>
  </td>
  </tr>
  <tr>
  <td style={{ width: "86px" }}>
  <p><strong>2009</strong></p>
  </td>
  <td style={{ width: "130px" }}>
  <p><strong>460 Corus Colors</strong></p>
  </td>
  <td style={{ width: "296px" }}>
  <p>&nbsp;</p>
  </td>
  </tr>
  <tr>
  <td style={{ width: "86px" }}>
  <p>&nbsp;</p>
  </td>
  <td style={{ width: "130px" }}>
  <p><strong>500 Corus Colors; 300 Panels &amp; Profiles; Colorsteels</strong></p>
  </td>
  <td style={{ width: "296px" }}>
  <p>&nbsp;</p>
  </td>
  </tr>
  </tbody>
  </table>
      </div>
      <div id="railway">
        <h2 className="h3">Shotton’s internal railway system and loco fleet</h2>
        <p>Like the small boats that plied their way to and from the Dee Estuary jetties for many years, the works&rsquo; loco fleet was of special interest to the outside world until disbanded in 1991.</p>
        <p>The works was opened in 1896 alongside the main North Wales-Merseyside rail line at Hawarden Bridge and as early as 1910 a visiting London journalist likened the scene to &ldquo;a large railway junction&rdquo; , describing the loco shed as &ldquo;of such size that many small railway companies need not have been ashamed to own it.&rdquo; By that time seven steam locomotive built by Hudswell, Clarke and Company Leeds were at work.</p>
        <p>Between the two world wars the fleet was increased to service two open hearth steelmaking plants as well as rolling mills and galvanising operations. There were nine 0-4-0 steamers named Vulcan, built 1900, Sealand, Planet, Windmill, Venus, Jupiter, Shotton, Mack and Remus, and eight six-wheelers, Diana, Romulus, Juno, Saturn, Mars, Neptune, Minerva and Vesta. Remus, bought in 1920, was believed to have been sold to locomotive dealers, Cudworth and Johnson, Wrexham. The engine was on hire to Rea, Birkenhead, in 1954.</p>
        <p>By 1948, the works had 46 miles of track and the internal locos were handling 5,000 wagons of main line traffic a week.</p>
        <p>In the early 1950s, as full integration of the plant was completed, a new marshalling and holding yard, known as Shotwick Sidings, was brought into use, adjacent to the incoming exchange sidings at Dee Marsh junction. Two more powerful steam locos, Titan and Thor, were added to the fleet, to move steel ingots from the new No.3 open hearth melting shop to the slab mill. They had regulators spanning the full width of the cab, so enabling them to be driven with ease from either side.</p>
        <p>A fleet of new main line wagons were bought at this time from Charles Roberts of Wakefield, to transfer iron ore from Bidston Docks at Birkenhead to the iron ore stock yard in the new blast furnace department. They had a capacity of 65 tons, were painted in grey and became known as &ldquo;Johnnies.&rdquo;&nbsp;</p>
        <p>The first diesel engine was introduced into the fleet in 1947 and the last of the steamers was taken out of service in 1958. The diesel locos came from a variety of builders such as Hudswell, Hunslet, North British, Yorkshire Engine Company and Barclay. The majority were 0-6-0 types but there were several 0-4-0&rsquo;s, known as Mickey Mouses.</p>
        <p>Generations of the Summers family were great railway enthusiasts and Peter and Tim Summers, great grandsons of the company founder, often went around the works on one of the steam locos, with their father, Sir Richard, at the controls. Sir Richard, a former director of London Midland and Scottish Railway Company, was at the controls of loco no. 92203 as it steamed into the Dee Marsh Sidings, to make the last delivery of iron ore to the works by British Rail on 6<sup>th</sup> November 1967. The event captured on canvas by the famous industrial artist, Terence Cuneo. The loco has since been bought by the equally famous wildlife artist and rail enthusiast, David Shepherd, and renamed &ldquo;Black Prince.&rdquo;&nbsp;</p>
        <p>To mark the end of the steam fleet, the company presented &ldquo;Vesta&rdquo; to the National Trust&rsquo;s rail museum at Penrhyn Castle, near Bangor, where it can still be seen. Built by Hudswell, Clarke in 1916, it is a side tank loco with inside cylinders measuring 15 1/2 inches x.20 ins. and coupled wheels, 3 ft. 4 &frac12; in. in diameter.</p>
        <p>Also among Penrhyn&rsquo;s collection of 12 engines is &ldquo;Hawarden&rdquo;, built for John Summers and Sons in 1899 and presented to the Trust in 1964. It worked at the company&rsquo;s original works at Stalybridge for many years and is a 0-4-0 saddle tank type.</p>
        <p>&ldquo;Diana&rdquo; ended her days at the works as a stationary boiler, adapted to produce steam at a reduced pressure of 80 lb. per square inch. . It was used to prevent pumps extracting water from the River Dee from freezing up in the winter.</p>
        <p>Up to the end of iron and steelmaking in 1980, 26 diesel locos operated on 70 miles of internal track, moving raw materials, iron and steel in various forms, and scrap arisings. By that time, many of the early diesels had been replaced by powerful 500 h.p. English Electric locos. Apart from a short stretch of 2 ft.6 in. narrow gauge track in the Cold Strip Mill department, the internal rail system was standard gauge, making it compatible with the main line.</p>
        <p>At its peak, the fleet was manned by eighty drivers, 12 spare drivers and 12 cleaners. Over the years, the works became totally self-sufficient to support a busy internal rail operation with a three-road loco shed, engineering shops, boiler shop, foundry, paint shop, wagon repair shop, permanent way section and traffic department.</p>
        <p>Following the closure of the iron and steel furnaces in 1980, most of the diesel engines were either scrapped or sold. Two of the narrow gauge locos are known to have survived, one at Statfold Barn Railway and the other, loco 50 ), was sold to Statfold Barn Railway for restoration by Neville Jones, a member of the Clwyd Railway Circle. Throughout the 1980s until disbandment of the loco fleet in 1991, five diesel locos moved thousands of tonnes of incoming hot rolled coil each week from the Dee Marsh Sidings to the cold mill outside coil storage area. The strip steel was produced in British Steel plants in Scotland and South Wales and was the feedstock for Shotton&rsquo;s cold rolling and strip coating processes.</p>
        <p>The end of the line for the fleet after 94 years came when remaining internal standard gauge operations were taken over by British Rail.</p>
        <p>Today (2014), the exchange sidings are still in place and Dee Marsh signal box still functions. Only a single track remains and coils of cold reduced annealed mild steel strip from South Wales are brought into the works daily by English, Welsh and Scottish Railway Company.</p>
        <p>Shotton&rsquo;s centenary in 1996 was perpetuated on the country&rsquo;s rail system when a Class 60 loco belonging to English, Welsh and Scottish Railway Company was named &ldquo; Shotton Works Centenary Year 1996.&rdquo;</p>
      </div>
      <div id="shipping-fleet">
        <h2 className="h3">Summers&rsquo; shipping fleet and jetties</h2>
        <p>In addition to providing cheap land for Summers, canalisation of the Dee also enabled the company to build jetties along the banks of the river so that small coasters could deliver raw materials such as pig iron to the works and move out finished steel products to Liverpool for transfer to ocean-going vessels. Harry Summers realised that exports of galvanised sheets would be a mainstay of the business as it developed and so it proved.</p>
        <p>Summers were pioneers not only in steel manufacturing but in shipping, using the relatively new internal combustion engine to power their fleet of small boats from 1910.&nbsp;</p>
        <p>The Buffalo and Rio Formoso, bought in 1897, were small steamers listed with Lloyds Register for &ldquo;coasting, river and canal service.&rdquo; Further additions to the fleet were powered by the marine internal combustion engine using oil.</p>
        <p>The fleet expanded rapidly from 1915 with boat builders, Abdela and Mitchell, Queensferry, and J.Crichton and Co., Connah&rsquo;s Quay, supplying many of the early long and short &ldquo;scooters&rdquo; of which there were 21 by 1923.</p>
        <p>They had distinctive names &ndash; Carita,Fleurita, Indorita, Eldorita, Warita &ndash; and their capacity ranged from 100 to 200 tons. The larger vessels brought pig iron from Workington, Millom and Barrow on the west coast of England for charging into the open hearth steel furnaces, and transported basic slag, a by product of the process, to Scotland and Ireland for use as fertiliser. The coasters were also used to ferry finished steel to Liverpool and Birkenhead for transfer to bigger ships.</p>
        <p>The Beetle Fleet, as it was known locally, transported millions of tons of steel, pig iron, coal, basic slag and raw materials from jetties built in the 1920s before being disbanded in the 1960s.</p>
        <p>Some of the boats had chequered lives after being sold by Summers, none more so than the motor vessels, Hawarden Bridge and Staley Bridge, the last to be sold. Both 300 tonners, 18 ft. long, with a beam of 24 ft., they were built by Scott and Sons, of Bowling, near Glasgow, in 1940.</p>
        <p>During the early years of the Second World War, they were in coastal trading in the Irish Sea from Scotland to the Bristol Channel, but with the approach of D-Day, were fitted out at Liverpool for the Normandy landings. They were used as petrol carriers for the Allied invasion and the Hawarden Bridge had the distinction of being the first Allied ship into Dunkirk harbour after the town&rsquo;s liberation.</p>
        <p>After being sold by Summers in the 1960s it was engaged in inter-island trading in the West Indies. It was spotted in a Barbados harbour by a Shotton technical consultant, Harry Johnson, while he was visiting customers in 1972. At the time over 90 per cent of buildings in that part of the world had corrugated galvanised steel roofs, and Shotton&rsquo;s Galvaprime product, in red, green and grey, was being used as roof and wall cladding on 500 new houses on the outskirts of Bridgetown.</p>
        <p>In 1979 the Hawarden Bridge was sunk in the Atlantic close to Miami to form an artificial reef after being found drifting with her engine room flooded and with no trace of the crew. Its last cargo was allegedly drugs.</p>
        <p>The MV Staley Bridge, which had been attached to the American forces who landed at Arromanches during the war, was sold in 1967 and is believed to lie on the sea bed at Dungeness after being holed during operations by a Folkestone salvage company to salvage a cargo of rails in 1981.</p>
        <p>The works&rsquo; remaining jetty on the Dee was used until the 1990s. Activity peaked in 1989 when seventy five motor vessels carried 98,959 tonnes of finished products to mainland Europe and Ireland, adding 12,827 tonnes to the previous best figure recorded in 1988. Average cargo weight was 1,319 tonnes.</p>
      </div>
      <div id="foundry-building">
        <h2 className="h3">The Foundry building</h2>
        <p>At the time that John Summers and Sons bought land at Hawarden Bridge for a new iron works in 1995 Chester Golf Club was using grass-covered sand flats in the vicinity for links. The only building on the course was a distinctive black-and-white clubhouse which doubled up as the golf professional&rsquo;s home.</p>
        <p>By December that year, Summers and Sons had acquired part of the golf links from the railway company. As a consequence the platform near Wrexham junction, built for the golf club, had to be moved to a position near Chester junction at an estimated cost of &pound;190. Several new links were created in the direction of Shotwick but the golf club closed soon after.</p>
        <p>The black and white building, a landmark for travellers on the Wrexham-Bidston railway line, became the site construction office for the new works, In 1902 it became the works&rsquo; chemical laboratory and following the opening in 1906 of a foundry to make rolls, brasses and general castings, it became the foundry office.</p>
        <p>It was demolished in 1981 following the closure of the foundry.</p>
      </div>
      <div id="housing-the-workers">
        <h2 className="h3">Housing the workers</h2>
        <p>Apart from a few coal mines, small iron and brick companies, local fishing and seafaring trade, Deeside was agricultural and sparsely populated at the time of the steelworks opening. John Summers therefore had to build not only a steelworks but an industrial community.</p>
        <p>Some of the first men recruited for the new works came from the Globe Works at Stalybridge, staying in local lodgings during the week end travelling back, some of cycles, to their families at the weekend. By 1896, 25 workmen&rsquo;s cottages were being built on the opposite side of the river in Shotton. As the works expanded, men travelled daily by train from Wrexham, Rhyl and Prestatyn.</p>
        <p>While the works grew on the north side of the Dee, the hamlets of Shotton and Connah&rsquo;s Quay on the opposite side mushroomed to cope with the influx of workers and their families. The works and the community grew together.</p>
        <p>Many men were recruited from the Black Country in mid England and the mills they operated were known as &ldquo;The Staffordshire Mills.&rdquo;</p>
        <p>In 1902 a group of managers in the works formed Summers Permanent Benefit Building Society to fund house purchase. James Summers was president and mortgages were repaid direct from workers&rsquo; wages.</p>
        <p>By 1910 the workforce had grown to 3,000 and the policy of building houses for workers financed through the company building society was helpful in attracting men to the area.</p>
        <p>An organisation called Sealand Tenants, registered under the Friendly Societies Act, was set up to benefit from government housing loans and between 1910 and 1913, the company conveyed 30 acres to the tenants&rsquo; body for the building of a housing estate, Garden City, within sight of the works. Altogether, 283 houses were built between 1910 and 1925. They housed solely steelworkers and their families until the 1950s by which time some 10 per cent. of the properties were rented by people unconnected with the works.</p>
        <p>Ownership of the estate transferred to British Steel in 1967 and in 1979, 196 houses not privately owned were acquired by a Collingwood Housing Association Limited, of Manchester.</p>
        <p>In 1980 John Summers Building Society merged with the Cheshire Building Society to protect the interests of its 9,600 mortgagors and investors following BSC&rsquo;s announcement to end iron and steelmaking. The Summers society had assets of &pound;7.5 million and reserves of &pound;373,000 at the time.</p>
      </div>
      <div id="land-ownership">
        <h2 className="h3">Summers/British Steel land ownership</h2>
        <p><strong>On 1<sup>st</sup> November 1895 the minutes of the Manchester, Sheffield and Lincoln Railway Company record that &ldquo;Summers and Sons of Stalybridge contemplate new iron works at Hawarden Bridge and have acquired some 40 acres by the Dee and Birkenhead railway and the River Dee, also an option on a 50 further acres.&rdquo; Summers are understood to have paid &pound;5.00 for the first parcel of land. </strong></p>
        <p>As a result of canalisation of the Dee Estuary between 1732-36, large areas of the estuary were no longer submerged by daily tides and by 1916 thousands of acres had been reclaimed for farming and urban and industrial development. Summers eventually bought some 10,000 acres of marshland of which 1,000 acres were used for industrial purposes as they expanded their Shotton business in the 20<sup>th</sup> century. The land was on both sides of the River Dee.</p>
        <p>On the north side of the river, the level of marshland was raised as land was needed for steelworks&rsquo; operations and eventually 120 acres were covered by buildings. Large tracts at Sealand and across the marshes towards Burton on the Wirral were farmed and the Garden City housing estate and Deeside Industrial Park cover land once owned by John Summers and Sons and later by British Steel.</p>
        <p>On the Shotton side of the river, and still in the ownership of Tata Steel (2014), are two areas, known as Rowley&rsquo;s Drive and Dee Parks, which have been used for sporting activities by employees and others since the 1950s.&nbsp;</p>
        <p>In 1979 The Royal Society for the Protection of Birds (RSPB) bought 5,040 acres of the Dee Estuary from British Steel Corporation for an undisclosed sum. The expanse of salt marshes and mud flats is one of the key estuaries in Europe for tens of thousands of breeding and migrating birds. It is rated a Grade One site of international importance for wading birds by the Nature Conservancy Council.</p>
        <p>The sale left over 5,000 acres in the vicinity of Shotton Works still in the ownership of BSC. Deeside Industrial Park now extends over several hundreds of acres, once Sealand Aerodrome, and other land close to the former General Office is earmarked for light industry and housing.</p>
        <p>Tata Steel is still a major landowner with Shotton Works extending over 500 acres.</p>
      </div>
      <div id="on-site-brewery">
        <h2 className="h3">On site brewery</h2>
        <p><strong>The thirst of the men working on the hand rolling mills was quenched by barley water and nettle pop brewed in the works until 1945.</strong></p>
        <p>At one time, boys crossed the Hawarden Bridge rail bridge to bring beer to the mill crews but this was stopped by the company and the employees resorted to drinking water which often led to severe stomach pains and cramp. Summers then agreed to the opening by William Blackham of two small breweries on the Top Yard area and close to the Marsh Mills, now Coatings Three. He charged 6d for a large bottle of his non-alcoholic drinks and 2d for a small one.</p>
        <p>One of the brews, known as &ldquo;all round&rdquo;, was made from herbs, brewers balm, dried nettles, wormwood and sugar. The pop was brewed in cast iron boilers and galvanised fermenting tanks and the mill operators would drink a gallon in a shift.</p>
        <p>After Mr.Blackham&rsquo;s death, the business was carried on by Mr. Jack Corbett, of Pentre, Queensferry, until its closure.&nbsp;</p>
      </div>
      <div id="grandfather-clocks">
        <h2 className="h3">Harry Summers’ grandfather clocks</h2>
        <p><strong>Henry Hall (Harry) Summers was a skilled designer and maker of grandfather clocks. It was a hobby he shared with an architect friend, James France, whose work included the steelworks&rsquo; General Office and the Midland Hotel in Manchester, and Kenneth Younghusband, who joined the steel company as chief electrical engineer in 1907.</strong></p>
        <p>The three met regularly at Harry&rsquo;s home, Cornist Hall, Flint, to repair antique clocks and between 1923 and 1929, to make at least nine long case clocks of their own design and substantial construction. A unique gravity escapement linking the pendulum to the lock mechanism was invented and patented by Younghusband. Some of the clocks incorporated day, month and perpetual calendar, even accounting for leap year, and their mechanism embodied many engineering principles to eliminate friction.</p>
        <p>Harry was a good carpenter and cut his own gear wheels using a lathe. The workmanship was superb. Everything, apart from the face and hands, was built by the three friends.</p>
        <p>On nationalisation of the steel industry in 1967, British Steel became custodians of four of the clocks and with the consent of the Summers family, one dated 1923 was presented to the Prescot Clock Museum at Knowsley, Liverpool.&nbsp;</p>
      </div>
      <div id="rifle-and-pistol-club">
        <h2 className="h3">Works’ Rifle and Pistol Club</h2>
        <p><strong>After the Second World War, the Ministry of Defence encouraged Home Guard units to form rifle clubs as a means of providing the country with a pool of trained riflemen. </strong></p>
        <p>The 19<sup>th</sup> Battalion Cheshire Home Guard Rifle Club was formed at the works in 1948 with Lt.Col. R.F.Summers as President, and Major A.R. Gray as chairman. In 1957, the Army Council having ruled that the title &ldquo;Home Guard&rdquo; be dropped, the club was re-named John Summers Rifle Club and then Summers (BSC) Rifle and Pistol Club.</p>
        <p>By 1971 the club had thirty members, two thirds of whom were taking part in competitions and rifle leagues with great success. The rifle range in the centre of the works (Drawing Office area) offered among the best facilities in Wales and was used for the Welsh Games rifle competitions in 1970. Several members were Welsh internationals.</p>
        <p>The club existed until 1999 when it was closed by local management following flooding which affected the electrical wiring system. Those members remaining at the time joined other clubs in the area. (Source: Roy Smallman e.mail 14<sup>th</sup> November 2014).</p>
      </div>
      <div id="sssi-sties">
        <h2 className="h3">SSSI sties and tern breeding rafts</h2>
        <p><strong>Throughout the gradual reclamation of hundreds of acres of marshland to allow expansion of the steelworks, every effort has been made to protect the flora and fauna of the area. Today&rsquo;s (2014) works extends over 500 acres with an abundance of wild life and rare flora within two areas designated by the Countryside Council for Wales as of Special Scientific Interest (SSSI).</strong></p>
        <p>One of the SSI areas includes a series of lagoons close to the Coatings Two complex which have three man-made breeding rafts for common tern, the graceful sea bird which has made the Dee Marshes its summer home for as long as records have been kept.</p>
        <p>Since the late 1960s hundreds have nested on the lagoons with Merseyside Ringing Group liaising with the works in the management of the breeding rafts. The project merited a Prince of Wales Environment Award in 1971 and during the 1970s and 1980s the bird was the works&rsquo; product quality symbol.</p>
        <p>The terns arrive from their winter quarters in North Africa in April and stay at the works until August. They lay eggs on nesting islands with deep sides to deter predators and bring feed from the nearby estuary for the young birds.</p>
        <p>Over 600 birds with 330 fledglings were reared between April and August in 1993 and the following year the size of the ternery was doubled with two original breeding rafts refurbished and a brand new one built, each measuring 160 ft. x 30 ft. The nesting islands were not used by terns for breeding between 2009-2013 but in 2014 300 pairs reared fledglings.</p>
      </div>
      <div id="weather-station">
        <h2 className="h3">Weather station</h2>
        <p><strong>Shotton Works was the location of Hawarden Bridge climatological station from 1901 until approx. 1998</strong>. In later years, a beehive type box close to the works&rsquo; central laboratory was one of over 500 locations across the country providing the national Met. Office with daily temperature and rainfall measurements as well as information on cloud cover, wind direction and speed. The information was invaluable to the works in dealing with customer complaints.</p>
        <p>The station closed in the late 1990s because of a proliferation of automatic monitoring sites.&nbsp;</p>
        <p>Records for the last 45 years of its operation show day temperatures ranging from minus 18.2 degrees C (1 degree F) on 11<sup>th</sup> January 1982 to 35.2 degrees C (95.3 degrees F) on 2<sup>nd</sup> August 1990. Annual rainfall in the last years of its existence averaged about 26 inches.</p>
      </div>
      <div id="sailing-club">
        <h2 className="h3">Shotwick Lake and sailing club</h2>
        <p><strong>In 1976 BSC Industry Limited, the job-creating subsidiary of BSC, was granted planning permission for the development of an industrial park covering 375 acres of Corporation owned land adjacent to the works. </strong></p>
        <p>Before the building of new roads and factories could start, hundreds of acres of low-lying land, previously Sealand aerodrome, had to be raised by over three feet to give correct fall for drains and sewers. The &pound;1 million operation involved some two million tonnes of sand being pumped two miles from the Shotwick area. The sand, suspended in water, was pumped along a pipeline, to leave a deep lake covering some 35 acres.</p>
        <p>The lake, surplus to works&rsquo; needs, was handed over in 1981 to the works&rsquo; Sports and Social Club for recreational use and in April 1984 Shotwick Lake was officially opened for dinghy sailing, windsurfing and canoeing. Its development for recreational was a voluntary effort by works&rsquo; employees and others interested in water sports.</p>
        <p>By February 1985 the newly-formed British Steel Shotton Sailing Club had a membership of 427 with a waiting list of 29 dinghy sailors and 62 windsurfers.</p>
        <p>In 2001 British Steel/ Corus sold off a parcel of land including the sailing lake to the Welsh Development Agency for further industrial development,</p>
        <p>Unable to obtain planning permission for industrial use, the WDA put the land up for sale in 2004. The re-named Corus Colors Sailing Club started a campaign to buy the lake and club buildings.</p>
        <p>Shotwick Lake Sailing Club, a company limited by guarantee and registered as a charity, was formed and with grant aid, a donation from Corus and money raised by members, Shotwick Lake was purchased from the Welsh Assembly Government in 2013 (Source: e.mail, Anthony Forster, sailing club commodore, 12<sup>th</sup> November 2014).</p>
      </div>
      <div id="artists">
        <h2 className="h3">The artist, Terence Cuneo  and the sculptor, Charles Fraughan</h2>
        <p><strong>Terence Cuneo ( 1907-1996) was a British artist renowned for his oil paintings of locomotives. However, his talents also encompassed many other aspects of life&hellip;.he was the official artist for the Coronation painting in 1953 and was commissioned by Queen Elizabeth the Second to paint royal equestrian portraits. </strong></p>
        <p>Between 1946 and 1956 Cuneo was commissioned by John Summers and Sons to paint three scenes at their iron and steelworks at Shotton on Deeside. They showed the casting bay of the open hearth melting shop ( July 1954), blast furnaces and the hot rolling mill. The original paintings were in the boardroom at Shotton Works until their transfer to British Steel plc&rsquo;s Mathern Palace, near Chepstow in 1996. In 2014 the paintings of the open hearth melting shop and the hot mill were transferred to the TATA records centre at Port Talbot and that of the blast furnaces was shipped to the TATA head office in India.</p>
        <p><strong>On the 1<sup>st</sup> January 1971 Charles Fraughan, a sculptor working in mild steel, became resident sculptor, with accommodation, equipment and materials provided, with BSC&rsquo;s Strip Mills Division.</strong></p>
        <p>He moved his electric welding and grinding machines from the workshop at his home in High Marton, Northwich, into a former World War Two radar station close to the Product Development Centre at Shotwick, on the outskirts of Shotton Works.</p>
        <p>Aged 40 he had given up a regular job in industry two years earlier and claimed to be the only sculptor in Britain working exclusively in mild steel. His work had been exhibited at universities and art centres throughout the north.</p>
        <p>BSC extended his contract for a second year by which time he had completed five major works and several smaller ones. His first major work &ldquo;The Giant of Hawarden Bridge&rdquo; stood 11 ft. high and represented a man carrying steel over three arches of the Hawarden Bridge rail bridge across the Dee. &ldquo;It shows a man striding out into the future with steel on his shoulder. In a way it symbolises the steel industry,&rdquo; he said.</p>
        <p>The piece has had a chequered history since, being moved from the works to the County Hall in Mold and then to the former Alyn and Deeside Council offices at Ewloe. It now stands in a public area at Ash Grove, Shotton.</p>
        <p>Two other 1971 creations, &ldquo;The Chain&rdquo; &ndash; a 12 ft. high sculpture weighing about five cwt. - and &ldquo;The Barrier&rdquo; went to British Steel&rsquo;s Head Office in London.&nbsp;</p>
      </div>
      <div id="deeside-industrial-park">
        <h2 className="h3">Deeside Industrial Park</h2>
        <p><strong>The development of a new industrial park for Deeside was announced in 1976 with Peter J. Summers, great grandson of John Summers, appointed Industry Co-ordinator, Shotton, with BSC Industry Limited, the job-creating subsidiary of BSC </strong></p>
        <p>Although the Government&rsquo;s final decision on the future of steelmaking was still awaited, new jobs were going to be needed on Deeside to replace those already being lost by rationalisation at the works and those under threat should iron and steel making end.</p>
        <p>BSC Industry Limited was initially granted planning permission for the development of an industrial park covering 375 acres of Corporation owned land adjacent to the works.</p>
        <p>Some two million tonnes of sand were pumped two miles from the Shotwick area to the site of the former Sealand aerodrome to prepare land for factories on the new park, being developed by BSC(Industry) Limited and the Welsh Development Agency. The &pound;1m operation raised some 300 acres of low lying land by over three feet to give correct fall for drains and sewers. The sand, suspended in water, was pumped along a pipeline, to leave a lake up to 35 feet deep and covering nearly forty acres.</p>
        <p>Ron Smith, Chairman of BSC (Industry ) Limited, cut the first sod on the new industrial park in November 1976.</p>
        <p>In 1977, Deeside was granted Full Development Area status by the Government and a New Jobs team for Shotton was formed in 1977, with former Flintshire County Council Chief Executive, T.M.Haydn Rees, as chairman. The Welsh Development Industry purchased over fifty acres of the new park and financed a &pound;4 million advance factory building programme providing 17 buildings.</p>
        <p>In August 1978 agreement was reached for the construction of the first factory &ndash; Wales&rsquo;s largest cold store, to be built for Iceland Frozen Foods at a cost of &pound;1.5m. In October 1978 the WDA agreed to purchase a further 250 acres from BSC.</p>
        <p>By 1979 nearly all the 17 advance factories had been let and nearly 1,000 new jobs were expected to be provided during the next three years. The first phase of the park was completed in 1980 with nine companies employing some 200 people. They included Iceland Frozen Foods, Burroughs Machines Limited, Freeman Chemicals Limited, a subsidiary of H.H. Robertson Co., Pittsburgh and an affiliated company of H.H.Robertson( UK) Limited, Ellesmere Port, and Dexter Midland Limited, part of the Dexter Corporation of America. The latter would produce industrial coatings for use principally in the steel coatings and metal packaging industries.</p>
        <p>Deeside Enterprise Trust Limited, a private company, was set up in 1982 to continue the works of BSC Industry in the area. It had the backing of regional and local government, BSC Industry, private enterprise and trades unions. T.M.Haydn Rees, was Chairman and Peter J. Summers, Managing Director.</p>
        <p>By 1986 some 3,000 people were working for forty companies located on the new park.</p>
        <p>Between 1976 and 1983, British Steel sold off the following areas of land.- 336 acres, Deeside Industrial Park ( 1976); 5,040 acres, RSPB ( 1979); 30 acres, Deeside Titanium ( 1980); 314 acres, Deeside Industrial Park, second phase (1983), 156 acres, former blast furnace site, Shotton Paper Mill ( 1983); 467 acres, remainder of &ldquo;heavy end&rdquo; site, Flintshire County Council ( 1983).</p>
        <p>Peter Summers retired from the position in May 1989.</p>
      </div>
    </div>
  </Layout>
)

export default AppendixPage
